<div  *ngIf="!isConnected">
    <a mat-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger">
        <mat-icon class="mat-icon-sm">person</mat-icon>
        <div class="flex flex-row items-center justify-center">
            <span class="mx-1 uppercase">S'identifier</span>
            <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
        </div>
    </a>
    <mat-menu  #userMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-menu-panel user-menu" >
        <span (mouseleave)="userMenuTrigger.closeMenu()">
            <a mat-menu-item routerLink="/sign-in">
                <span>Connexion</span> 
            </a>
            <a mat-menu-item routerLink="/sign-up"> 
                <span>Créer un compte</span> 
            </a>
        </span>
    </mat-menu>  
</div>

<div *ngIf="isConnected && user">
    <a  mat-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger"  >
        <mat-icon class="mat-icon-sm">person</mat-icon>
        <!-- Tarik S. -->
        <div class="flex flex-row items-center justify-center">
            <span class="mx-1 uppercase"> {{user.username}} </span>
            <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
        </div>
    </a>
    <mat-menu #userMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-menu-panel user-menu" >
        <span (mouseleave)="userMenuTrigger.closeMenu()">
            <div class="flex items-center space-x-1 p-1 rounded-md shadow-sm">
              <!-- Profile Image on the Left -->
              <div class="flex-shrink-0">
                <img
                        (error)="handleImageError($event)"
                        [src]="getAvatar(user?.avatar ?? user?.picture)" alt="user-image" class="w-12 h-12 rounded-full">
              </div>
              <div class="flex flex-col">
                <p class="m-0 text-lg font-medium text-gray-700">
                  {{ user?.first_name + ' ' + user?.last_name }}
                </p>
                <small class="text-gray-500 italic">Patient</small>
              </div>
            </div>
            <div class="divider"></div>
            <a mat-menu-item routerLink="/patient-area"> 
                <mat-icon class="mat-icon-sm">account_circle</mat-icon>
                <span>Mon Compte</span> 
            </a>
            <a mat-menu-item routerLink="/patient-area/rendez-vous"> 
                <mat-icon class="mat-icon-sm">calendar_today</mat-icon>
                <span>Mes Rendez-Vous</span> 
            </a>
            <div class="divider"></div>
            <a mat-menu-item (click)="logout()"> 
                <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
                <span>Déconnexion</span>
            </a>
        </span>
    </mat-menu>  
</div>