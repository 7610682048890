<div class="step-content">
<!--    Doctor details-->
    <mat-card class="rounded-lg shadow-md p-4">
        <div class="flex items-start space-x-4">
            <!-- Doctor Image -->
            <div class="w-24 h-24 bg-white rounded-md shadow-md">
                <a class="block overflow-hidden p-px rounded-md" [routerLink]="['/'+dataPractice.url]">
                    <img
                            [src]="getAvatarDoc(dataPractice?.picture)"
                            alt="{{dataPractice?.title}}"
                            (error)="handleImageError($event)"
                            class="w-full h-full rounded-md"
                    />
                </a>
            </div>

            <!-- Doctor Information -->
            <div class="flex-1">
                <!-- Doctor Name -->
                <h4 class="text-lg font-semibold text-gray-800 m-0">
                    <a [routerLink]="['/'+dataPractice.url]" class="hover:text-blue-500">{{ dataPractice?.title }}</a>
                </h4>

                <!-- Speciality -->
                <div class="flex items-center space-x-2 mt-1">
                    <div class="w-6 h-6">
                        <img
                                src="https://cdn.tictacsante.com/assets/images/icon-cardio.jpg"
                                alt="Speciality"
                                class="speciality-img w-full h-full"
                        />
                    </div>
                    <span class="text-sm speciality-name">{{ dataPractice?.speciality }}</span>
                </div>

                <!-- Rating -->
                <div class="flex items-center space-x-1 mt-2 text-yellow-400">
                    <mat-icon class="text-xl">star_border</mat-icon>
                    <mat-icon class="text-xl">star_border</mat-icon>
                    <mat-icon class="text-xl">star_border</mat-icon>
                    <mat-icon class="text-xl">star_border</mat-icon>
                    <mat-icon class="text-xl">star_border</mat-icon>
                    <span class="text-sm text-gray-600">(0)</span>
                </div>

                <!-- Location -->
                <div class="flex items-center space-x-1 mt-2 text-gray-600 text-sm">
                    <mat-icon>location_on</mat-icon>
                    <p class="m-0">{{ dataPractice?.city }}</p>
                </div>
            </div>
        </div>
    </mat-card>
<!--    Summary-->
    <div class="mt-4">
        <div class="w-full">
            <mat-card class="rounded-lg shadow-md p-4">
                <!-- Title -->
                <h2 class="text-2xl font-semibold mb-4 text-gray-800">Détails du rendez-vous</h2>
                <hr class="border-t border-gray-200 mb-4">

                <!-- Appointment Details -->
                <div class="flex justify-between text-gray-700 font-medium">
                    <span class="font-bold">Consultation</span>
                    <span class="text-gray-500">Au cabinet</span>
                </div>

                <div class="flex justify-between text-gray-700 font-medium mt-2">
                    <span class="font-bold">Date</span>
                    <span class="text-gray-500">{{ timeSlot.date | date }}</span>
                </div>

                <div class="flex justify-between text-gray-700 font-medium mt-2">
                    <span class="font-bold">Heure</span>
                    <span class="text-gray-500">{{ time.hour_text }}</span>
                </div>

                <div class="flex justify-between text-gray-700 font-medium mt-2">
                    <span class="font-bold">Motif</span>
                    <span class="text-gray-500">{{ motif?.type_name | titlecase }}</span>
                </div>
            </mat-card>
        </div>
    </div>
    <div>
        <div class="flex justify-between items-center mt-3">
            <button matStepperPrevious class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center" type="button">
                <mat-icon>navigate_before</mat-icon>
                <span class="uppercase">Retour</span>
            </button>
            <button matStepperNext class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center" type="button">
                <span class="uppercase mr-1">Suivant</span>
                <mat-icon>navigate_next</mat-icon>
            </button>
        </div>
    </div>
</div>

