<div class="step-content py-4" *ngIf="practice && proche">
    <!-- Confirmation Message -->
    <div class="bg-green-100 text-green-800 p-4 rounded-md text-center mb-6">
        TicTacSanté vous confirme votre rendez-vous.
    </div>

    <!-- Patient Info Section -->
    <div class="mb-6">
        <h5 class="text-lg font-semibold text-gray-700 mb-2">Patient</h5>
        <mat-card class="bg-white shadow-md rounded-lg">
            <div class="flex items-center p-4 space-x-4">
                <!-- Patient Image -->
                <div class="w-24 h-24 bg-white rounded-md shadow-md">
                    <div class="block overflow-hidden p-px rounded-md">
                        <img
                                [src]="getAvatar(proche.picture ?? proche.avatar)"
                                alt="{{proche.first_name + '' + proche.last_name}}"
                                (error)="handleImageError($event)"
                                class="w-full h-full rounded-md"
                        />
                    </div>
                </div>

                <!-- Patient Info -->
                <div class="flex-1">
                    <!-- Patient Name -->
                    <h4 class="text-lg font-semibold text-gray-800 m-0">
                        <span class="hover:text-blue-500">{{proche.first_name + " " + proche.last_name }}</span>
                    </h4>

                    <!-- Category proche -->
                    <div class="flex items-center space-x-2 mt-1">
                        <span class="text-sm speciality-name">{{ proche.category }}</span>
                    </div>

                    <!-- Location -->
                    <div class="flex items-center space-x-1 mt-2 text-gray-600 text-sm">
                        <mat-icon>location_on</mat-icon>
                        <p class="m-0">{{ proche?.city }}</p>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>

    <!-- Practitioner Info Section -->
    <div class="mb-6">
        <h5 class="text-lg font-semibold text-gray-700 mb-2">Praticien</h5>
        <mat-card class="bg-white shadow-md rounded-lg">
            <div class="flex items-center p-4 space-x-4">
                <!-- Doctor Image -->
                <div class="w-24 h-24 bg-white rounded-md shadow-md">
                    <a class="block overflow-hidden p-px rounded-md" href="/">
                        <img
                                [src]="getAvatarDoc(dataPractice?.picture)"
                                alt="{{dataPractice?.title}}"
                                (error)="handleImageError($event)"
                                class="w-full h-full rounded-md"
                        />
                    </a>
                </div>

                <!-- Doctor Information -->
                <div class="flex-1">
                    <!-- Doctor Name -->
                    <h4 class="text-lg font-semibold text-gray-800 m-0">
                        <a href="/" class="hover:text-blue-500">{{ dataPractice?.title }}</a>
                    </h4>

                    <!-- Speciality -->
                    <div class="flex items-center space-x-2 mt-1">
                        <div class="w-6 h-6">
                            <img
                                    src="https://cdn.tictacsante.com/assets/images/icon-cardio.jpg"
                                    alt="Speciality"
                                    class="speciality-img w-full h-full"
                            />
                        </div>
                        <span class="text-sm speciality-name">{{ dataPractice?.speciality }}</span>
                    </div>

                    <!-- Rating -->
                    <div class="flex items-center space-x-1 mt-2 text-yellow-400">
                        <mat-icon class="text-xl">star_border</mat-icon>
                        <mat-icon class="text-xl">star_border</mat-icon>
                        <mat-icon class="text-xl">star_border</mat-icon>
                        <mat-icon class="text-xl">star_border</mat-icon>
                        <mat-icon class="text-xl">star_border</mat-icon>
                        <span class="text-sm text-gray-600">(0)</span>
                    </div>

                    <!-- Location -->
                    <div class="flex items-center space-x-1 mt-2 text-gray-600 text-sm">
                        <mat-icon>location_on</mat-icon>
                        <p class="m-0">{{ dataPractice?.city }}</p>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>

    <!-- Appointment Details Section -->
    <div class="mt-4">
        <div class="w-full">
            <mat-card class="rounded-lg shadow-md p-4">
                <!-- Title -->
                <h2 class="text-2xl font-semibold mb-4 text-gray-800">Détails du rendez-vous</h2>
                <hr class="border-t border-gray-200 mb-4">

                <!-- Appointment Details -->
                <div class="flex justify-between text-gray-700 font-medium">
                    <span class="font-bold">Consultation</span>
                    <span class="text-gray-500">Au cabinet</span>
                </div>

                <div class="flex justify-between text-gray-700 font-medium mt-2">
                    <span class="font-bold">Date</span>
                    <span class="text-gray-500">{{ timeSlot.date | date }}</span>
                </div>

                <div class="flex justify-between text-gray-700 font-medium mt-2">
                    <span class="font-bold">Heure</span>
                    <span class="text-gray-500">{{ time.hour_text }}</span>
                </div>

                <div class="flex justify-between text-gray-700 font-medium mt-2">
                    <span class="font-bold">Motif</span>
                    <span class="text-gray-500">{{ motif?.type_name | titlecase }}</span>
                </div>

                <div *ngIf="comment" class="flex justify-between text-gray-700 font-medium mt-2">
                    <span class="font-bold">Commentaire</span>
                    <span class="text-gray-500">{{comment}}</span>
                </div>
            </mat-card>
        </div>
    </div>

    <!-- Action Button -->
    <div class="flex justify-between items-center mt-3">
        <button (click)="rendezVous()" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center" type="button">
            <span class="uppercase mr-1">Mes rendez-vous</span>
            <mat-icon>navigate_next</mat-icon>
        </button>
    </div>
</div>
