<div class="p-3" *ngIf="appointment">
    <div class="theme-container">
        <mat-card ngClass.lt-lg="p-0">
            <mat-horizontal-stepper #horizontalStepper linear="true" class="submit-property rounded-md" (selectionChange)="onStepChange($event)">
                <mat-step editable="true"  label="Authentification" state="auth">
                    <app-authentication  (newItemEvent)="addItem($event)" [connected]="connected" ></app-authentication>
                </mat-step>

                <mat-step editable="true" label="Vérification" state="syn">
                    <app-verification></app-verification>
                </mat-step>

                <mat-step editable="true" label="Synthèse" state="syn">
                    <app-sammary></app-sammary>
                </mat-step>

                <mat-step editable="true" label="Choix de proches" state="choice">
                    <div *ngIf="isAuthenticated">
                        <app-choice></app-choice >
                    </div>
                </mat-step>

                <mat-step editable="true" label="Confirmation" state="conf">
                    <app-confirmation></app-confirmation>
                </mat-step>
            </mat-horizontal-stepper>
        </mat-card>
    </div>
</div>
