<div class="step-content">
    <mat-card class="rounded-lg shadow-md p-4">
        <div class="flex items-start space-x-4">
            <!-- Doctor Image -->
            <div class="w-24 h-24 bg-white rounded-md shadow-md">
                <a class="block overflow-hidden p-px rounded-md" [routerLink]="['/'+dataPractice.url]">
                    <img
                            [src]="getAvatarDoc(dataPractice?.picture)"
                            alt="{{dataPractice?.title}}"
                            (error)="handleImageError($event)"
                            class="w-full h-full rounded-md"
                    />
                </a>
            </div>

            <!-- Doctor Information -->
            <div class="flex-1">
                <!-- Doctor Name -->
                <h4 class="text-lg font-semibold text-gray-800 m-0">
                    <a [routerLink]="['/'+dataPractice.url]" class="hover:text-blue-500">{{ dataPractice?.title }}</a>
                </h4>

                <!-- Speciality -->
                <div class="flex items-center space-x-2 mt-1">
                    <div class="w-6 h-6">
                        <img
                                src="https://cdn.tictacsante.com/assets/images/icon-cardio.jpg"
                                alt="Speciality"
                                class="speciality-img w-full h-full"
                        />
                    </div>
                    <span class="text-sm speciality-name">{{ dataPractice?.speciality }}</span>
                </div>

                <!-- Rating -->
                <div class="flex items-center space-x-1 mt-2 text-yellow-400">
                    <mat-icon class="text-xl">star_border</mat-icon>
                    <mat-icon class="text-xl">star_border</mat-icon>
                    <mat-icon class="text-xl">star_border</mat-icon>
                    <mat-icon class="text-xl">star_border</mat-icon>
                    <mat-icon class="text-xl">star_border</mat-icon>
                    <span class="text-sm text-gray-600">(0)</span>
                </div>

                <!-- Location -->
                <div class="flex items-center space-x-1 mt-2 text-gray-600 text-sm">
                    <mat-icon>location_on</mat-icon>
                    <p class="m-0">{{ dataPractice?.city }}</p>
                </div>
            </div>
        </div>
    </mat-card>
    <div class="mt-4">
        <div class="w-full">
            <mat-card class="rounded-lg shadow-md p-4">
                <!-- Spinner -->
                <mat-spinner
                        [diameter]="48"
                        color="green"
                        class="block mx-auto my-10"
                        *ngIf="spin">
                </mat-spinner>

                <!-- Content -->
                <div *ngIf="!spin">
                    <!-- Available Slot -->
                    <div *ngIf="isAvailableSlot && timeSlot">
                        <h2 class="text-2xl font-semibold text-gray-800 mb-4">Confirmez l'heure du rendez-vous</h2>
                        <hr class="border-t border-gray-200 mb-4">

                        <!-- Appointment Details -->
                        <div class="flex justify-between text-gray-700 font-medium">
                            <span class="font-bold">Consultation</span>
                            <span class="text-gray-500">Au cabinet</span>
                        </div>

                        <div class="flex justify-between text-gray-700 font-medium mt-2">
                            <span class="font-bold">Date</span>
                            <span class="text-gray-500">{{ timeSlot.date | date }}</span>
                        </div>

                        <div class="flex justify-between text-gray-700 font-medium mt-2">
                            <span class="font-bold">Heure</span>
                            <span class="text-gray-500">{{ time.hour_text }}</span>
                        </div>

                        <div class="flex justify-between text-gray-700 font-medium mt-2">
                            <span class="font-bold">Motif</span>
                            <span class="text-gray-500">{{ motif?.type_name | titlecase }}</span>
                        </div>
                    </div>

                    <!-- Slot Not Available -->
                    <div *ngIf="!errorNewPatient && !isAvailableSlot">
                        <div class="flex items-center p-4 mx-2 bg-yellow-50 text-yellow-800 rounded-md mb-6">
                            <svg aria-hidden="true" class="w-5 h-5 mr-3 text-yellow-600" fill="currentColor" viewBox="0 0 20 20">
                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path>
                            </svg>
                            <div class="text-sm font-medium">
                                Créneaux non disponible <br />
                                Nous sommes désolés, ce créneau n'est plus disponible. Veuillez en choisir un autre.
                            </div>
                        </div>

                        <div class="mx-2 my-6">
                            <h2 class="text-xl font-semibold text-gray-800 mb-4">Choisissez la date de consultation</h2>
                            <app-planning-list
                                    *ngIf="dataPractice"
                                    class="m-4"
                                    [practice]="dataPractice"
                                    [reasons]="motif"
                                    (emitSelectSlot)="selectSlot($event)">
                            </app-planning-list>
                        </div>
                    </div>

                    <!-- Error: New Patient Not Accepted -->
                    <div *ngIf="errorNewPatient">
                        <div class="flex items-center p-4 mx-2 bg-yellow-50 text-yellow-800 rounded-md mb-6">
                            <svg aria-hidden="true" class="w-5 h-5 mr-3 text-yellow-600" fill="currentColor" viewBox="0 0 20 20">
                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path>
                            </svg>
                            <div class="text-sm font-medium">
                                Le praticien n'accepte pas de nouveaux patients.
                            </div>
                        </div>
                        <div class="text-center">
                            <h2 class="text-2xl font-bold text-gray-800 mb-4">Choisissez un autre praticien</h2>
                            <button
                                    routerLink="/"
                                    class="px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600 transition duration-300">
                                Cliquez ici
                            </button>
                        </div>
                    </div>
                </div>
            </mat-card>
            <!-- Navigation Buttons -->
            <div *ngIf="isAvailableSlot && timeSlot" class="flex justify-between items-center mt-3">
                <button
                        [routerLink]="['/'+dataPractice.url]"
                        class="flex items-center px-4 py-2 bg-blue-500 text-white font-bold uppercase rounded hover:bg-blue-700 transition duration-300">
                    <mat-icon>navigate_before</mat-icon>
                    Retour
                </button>
                <button
                        [disabled]="isDisabledNextButton"
                        matStepperNext
                        class="flex items-center px-4 py-2 bg-blue-500 text-white font-bold uppercase rounded hover:bg-blue-700 transition duration-300">
                    Suivant
                    <mat-icon>navigate_next</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>