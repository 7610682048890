<div class="step-content">
    <!-- Container -->
    <mat-card class="w-full md:max-w-4xl rounded-lg shadow-lg">
        <!-- Header -->
        <div class="h-16 flex items-center border-b border-gray-200 p-6">
            <h2 class="text-2xl font-bold text-gray-700">Choisissez un patient</h2>
        </div>

        <!-- Patient List -->
        <div class="px-6 py-4 max-h-screen overflow-y-auto">
            <div
                    *ngFor="let data of dataSource"
                    class="flex justify-between items-center p-4 mb-4 rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-shadow bg-white"
            >
                <!-- Patient Info -->
                <div class="flex items-center space-x-4">
                    <img
                            class="rounded-full h-12 w-12 object-cover"
                            [src]="getAvatar(data?.picture ?? data?.avatar)"
                            alt="{{data?.first_name}} {{data?.last_name}}"
                    />
                    <div>
                        <div class="text-sm font-semibold text-gray-700">
                            {{data?.first_name}} {{data?.last_name}}
                        </div>
                        <span *ngIf="data?.category" class="max-w-40 truncate whitespace-nowrap inline-block py-0.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-800/30 dark:text-blue-500">
                            {{data?.category}}
                        </span>
                    </div>
                </div>

                <!-- Checkbox -->
                <mat-checkbox
                        (change)="onChange(data?.reference)"
                        [checked]="selected === data?.reference"
                        (click)="$event.stopPropagation()"
                        color="primary"
                >
                </mat-checkbox>
            </div>
            <form>
                <mat-form-field appearance="outline" class="w-full bg-white">
                    <textarea matInput placeholder="Commentaire (Optionnel)..." [formControl]="commentControl" ></textarea>
                </mat-form-field>
            </form>
        </div>

    </mat-card>
    <!-- Footer Buttons -->
    <div class="flex justify-between items-center mt-3">
        <!-- Back Button -->
        <button
                matStepperPrevious
                class="flex items-center bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition"
                matTooltip="Retour"
                type="button"
                matTooltipPosition="below"
        >
            <mat-icon class="mr-1">navigate_before</mat-icon>
            <span class="uppercase">Retour</span>
        </button>

        <!-- Confirm Button -->
        <button
                class="flex items-center bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition"
                type="button"
                matTooltipPosition="below"
                matTooltip="Confirmer le rendez-vous"
                (click)="confirmerRdv()"
        >
        <span
                class="uppercase mr-2 truncate btn-confirm-rdv"
        >
            Confirmer le rendez-vous
        </span>
            <mat-icon>navigate_next</mat-icon>
        </button>
    </div>
</div>