<div class="calendar-wrapper">
    <div class="calendar-week pr-2.5">
        <button class="nav-button-left" mat-icon-button type="button" (click)="previousDays()"
                [ngStyle]="{cursor: (isToday) ? 'default' : 'pointer'}" [disabled]="isToday || errorOccurred">
            <mat-icon [ngStyle]="{color: (isToday || errorOccurred) ? '#c1d7d7' : ''}">chevron_left</mat-icon>
        </button>
        <ul class="">
            <li *ngFor="let TimeSlot of timesSlot" class="day flex row-auto justify-center items-center">
                <div class="grid py-1 mr-1">
                    <div>{{ TimeSlot.day_name }}</div>
                    <div>{{ TimeSlot.month }}</div>
                </div>
                <div class="rounded-full bg-white text-blue-600 space">{{TimeSlot.day}}</div>
                <span class="day-counter">{{ TimeSlot.count_slots }}</span>
            </li>
        </ul>
        <button class="nav-button-right" (click)="nextDays()" [disabled]="errorOccurred"  mat-icon-button>
            <mat-icon>chevron_right</mat-icon>
        </button>
    </div>
    <div class="withScroll container-scroll" data-height="330">
        <mat-spinner *ngIf="spin" color="warn" style="margin:0 auto; margin-top: 100px;"></mat-spinner>
        <div class="calendar-hours">
            <ul *ngIf="!spin">
                <li *ngFor="let TimeSlot of timesSlot">
                    <ul>
                        <li *ngFor="let time of TimeSlot.times" (click)="startProcessRdv(time, TimeSlot)">
                            <span  >{{ time?.hour_text }}</span>
                        </li>
                    </ul>
                    <ul class="flex justify-around" *ngIf="TimeSlot?.times?.length <= 0">
                        <li class="empty">
                            <span>
                            </span>
                        </li>
                    </ul>
                </li>
            </ul>
            <div *ngIf="errorOccurred && !spin" class="flex items-center w-full cursor-pointer" role="alert">
                <div class="flex md:w-auto m-auto items-center p-4 mb-4 mx-2 text-ttsBluePrimary bg-white alert-message-style-info shadow-lg shadow-grey-500/50" role="alert">
                    <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-2 text-ttsBluePrimary" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zM305 305c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47z"/></svg>
                    <div class="ms-3 text-sm font-medium text-ttsBluePrimary">
                        Aucune disponibilité en ligne
                    </div>
                </div>
            </div>
            <div *ngIf="(emptyPlanningTimes || nextDateApp) && !spin" class="flex items-center w-full cursor-pointer" (click)="goToNextDateApp()">
                <div class="flex md:w-auto m-auto items-center p-4 mb-4 mx-2 text-ttsBluePrimary bg-white alert-message-style-info shadow-lg shadow-grey-500/50" role="alert">
                    <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-2 text-ttsBluePrimary" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L64 64C28.7 64 0 92.7 0 128l0 16 0 48L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-256 0-48 0-16c0-35.3-28.7-64-64-64l-40 0 0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L152 64l0-40zM48 192l352 0 0 256c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256z"/></svg>
                    <div class="ms-3 text-sm font-medium text-ttsBluePrimary">
                        Prochain RDV le <strong>{{nextDateApp | date}}</strong>
                    </div>
                    <mat-icon class="icon-style">keyboard_arrow_right</mat-icon>
                </div>
            </div>
            <div *ngIf="errorNewPatient && !spin" class="flex md:w-auto m-auto items-center p-4 mb-4 mx-2 text-ttsBluePrimary bg-white alert-message-style-info shadow-lg shadow-grey-500/50" role="alert">
                <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-2 text-ttsBluePrimary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div class="ms-3 text-sm font-medium">
                    Ce praticien réserve la prise de rendez-vous en ligne aux patients déjà suivis
                </div>
            </div>
            <div *ngIf="emptyPlanning && !spin" class="flex md:w-auto m-auto items-center p-4 mb-4 mx-2 text-ttsBluePrimary bg-white alert-message-style-info shadow-lg shadow-grey-500/50" role="alert">
                <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-2 text-ttsBluePrimary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div class="ms-3 text-sm font-medium">
                    Pas de rendez-vous en ligne disponible pour ce praticien. Veuillez appeler le
                    <span class="font-bold text-gray-500">{{ practice?.phone }}</span> pour prendre un rendez-vous
                </div>
            </div>
        </div>
    </div>
</div>
