<div *ngIf="!connected" class="step-content">
  <div class="p-3 page-wrapper">
    <div class="theme-container">
      <div [style.max-width.px]="600" class="grid m-auto p-2 sm:p-10 bg-white border border-gray-200 rounded-lg shadow">
        <app-auth-signin *ngIf="hidden" (nextStep)="nextStep()" [typeAuth]="'booking'"></app-auth-signin>
        <div class="block-or padding-bottom-15" *ngIf="hidden">
          <i>OU</i>
          <hr>
        </div>
        <!-- create account -->
        <div *ngIf="hidden">
          <a mat-button href="javascript:void(0)" style="color: black;" class="w-full">Vous n'avez pas
            encore de compte ?</a>
        </div>
        <div class="text-center" *ngIf="!hidden">
          <h2 class="title">Avez-vous déjà un compte <strong>TicTacSanté ?</strong> </h2>
        </div>
        <!-- Action  -->
        <div class="text-center mt-2" *ngIf="hidden">
            <button mat-raised-button [color]="'primary'" (click)="showpage()" class="py-2 px-4 w-full">
                Inscrivez-vous
            </button>
        </div>
        <div class="text-center mt-2" *ngIf="!hidden">
            <button mat-raised-button [color]="'primary'" (click)="showpage()" class="py-2 px-4 w-full">
                Connectez-vous
            </button>
        </div>
        <div class="block-or padding-bottom-15 mt-4" *ngIf="!hidden">
          <i>OU</i>
          <hr>
        </div>
        <div class="w-full" *ngIf="!hidden">
          <app-inscription [typeAuth]="'booking'"></app-inscription>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="step-content" *ngIf="connected">
  <div class="message-box success border mb-16">
    Vous êtes déja connectés !
  </div>
  <div class="flex flex-row justify-end items-center mt-3 p-2">
    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex flex-row items-center" type="button">
      <span class="mx-1 uppercase">Suivant</span>
      <mat-icon>navigate_next</mat-icon>
    </button>
  </div>
</div>