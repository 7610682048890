<div class="card" style="padding: 20px;">
  <h1 mat-dialog-title class="section-title text-center" style="font-size: 2em; color: #0099da;">{{ data.title }}</h1>
  <div class="text-base font-light text-center my-2">
    Vous allez recevoir dans quelques instants un SMS
    <br />
  </div>
  <div class="flex justify-center items-center ml-1">
    <div class="flex items-center mr-2">
      <mat-icon matPrefix *ngIf="isLoading" class="mr-1 text-muted animate-spin">refresh</mat-icon>
    </div>
  </div>
  <br />
  <!-- Actions -->
  <div class="mt-2 mx-auto flex justify-around">
    <form [formGroup]="validateForm" [style.fontSize.px]="12">
      <!-- Code -->
        <mat-form-field appearance="outline" class="w-100 mt-1">
            <mat-label>Code</mat-label>
            <mat-icon matPrefix class="mr-1 text-muted">lock</mat-icon>
            <input matInput formControlName="code" maxlength="6" required>

            <mat-error *ngIf="validateForm.controls.code.errors?.required">
                Le code est obligatoire. Veuillez entrer un code valide.
            </mat-error>

            <mat-error *ngIf="validateForm.controls.code.errors?.pattern || validateForm.controls.code.errors?.pattern">
                Le code doit contenir exactement 6 chifres.
            </mat-error>
        </mat-form-field>

        <div  *ngIf="errorMessage" class="flex items-center p-4 my-auto text-red-800 bg-red-50 alert-message-style-danger mb-4" role="alert">
            <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <div class="ms-3 text-sm font-medium">
                <strong>Code de validation incorrect</strong>
            </div>
        </div>

      <button [disabled]="validateForm.invalid" mat-raised-button [color]="'primary'" [ngClass]="{' mt-6': validateForm.touched && validateForm.invalid}" class="w-full py-2" (click)="valider()">
        Valider
      </button>
    </form>
  </div>
</div>
