export const environment = {
  production: true,
  url: '',
  url_java     : 'https://svrx.api.tictacsante.com/rest',
  url_api     : 'https://api.tictacsante.com/rest',
  google_apiKey: 'AIzaSyDet-CrlVTg8WWSTbDLdivRzDTBdVpeTUI',
  gtag_id: 'G-KHCENTGE2B',
  recaptcha: {
    'siteKey': '6Ldy37IqAAAAAGOrWOC_cdQwz9auGtZl0NlrwPci',
  }
};
